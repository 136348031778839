import { HeaderSubtract } from "../components/layout/header-subtract";
import React, { CSSProperties, SetStateAction, useEffect, useState } from "react";
import { Form, useForms } from "../components/controls/form";
import { Button } from "../components/controls/button";
import { Field, FieldLabel } from "../components/controls/field";
import Parse from "parse";
import Upload from "rc-upload";

import { ReactComponent as UploadIcon } from "../../assets/icons/upload.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit_pencil.svg";
import { Modal, useModal } from "../components/controls/modal";
import Joi from "joi";
import { inlineErr } from "../../app/utils/error-handler-util";
import { saveCompany } from "../../app/services/company-service";
import { useCurrentUser } from "../../app/services/user-service";
import { useNavigate } from "react-router";
import { routes } from "../../app/routes";
import { Avatar } from "@material-ui/core";
import { _t } from "../../app/translate/translate-wrapper";
import { TranslateButton } from "../components/translate-button";

const schema = Joi.object({
  contactFirstName: Joi.string().required().min(3),
  contactLastName: Joi.string().required().min(3),
  contactEmail: Joi.string().email({ tlds: false }).required(),
  companyName: Joi.string().required(),
  websiteLink: Joi.string(),
  phoneNumber1: Joi.string().required().min(3),
  phoneNumber2: Joi.string().min(3),
  industryType: Joi.string().required(),
  otherIndustry: Joi.string()
    .max(30)
    .when("industryType", { is: _t("other"), then: Joi.required(), otherwise: Joi.optional() }),
  size: Joi.string().required(),
  numberEmployees: Joi.string().required(),
  trainingProcess: Joi.string().required().min(3),
  about: Joi.any(),
}).unknown(true);

export function FormControl(props: { classContainer?: string; title: string; actionElm: any; style?: CSSProperties }) {
  const classContainer = props.classContainer === undefined ? "l-content-small" : props.classContainer;

  return (
    <div className="l-flex-align-center" style={{ background: "#FBFBFB", height: "70px" }}>
      <div className={classContainer + " l-flex-between"} style={props.style}>
        <strong style={{ fontSize: "18px" }}>{props.title}</strong>
        {props.actionElm}
      </div>
    </div>
  );
}

export function FormSectionTitle({ children, style = {} as CSSProperties }) {
  return (
    <div
      style={{
        fontSize: "16px",
        lineHeight: "30px",
        color: "#325E8C",
        margin: "21px 0",
        ...style,
      }}
    >
      <strong>{children}</strong>
    </div>
  );
}

export default function AboutCompanyPage() {
  const currentUser = useCurrentUser();
  const company = currentUser?.company;
  const navigate = useNavigate();

  const [companyLogo, setCompanyLogo] = useState<any | null>(null);

  const industryTypeList = [
    _t("food"),
    _t("healthcare_pharma"),
    _t("manufacturing"),
    _t("logistics"),
    _t("fashion"),
    _t("ecommerce"),
    _t("technology"),
    _t("other"),
  ];

  const { genericFieldProps, onSubmit, valid, setFormValue, formState } = useForms(
    schema,
    { initialFormValue: currentUser },
    async (formIsValid, formState) => {
      console.log("state", formState);
      console.log(formIsValid);
      if (formIsValid && currentUser) {
        const companyRegistered = Boolean(currentUser.company);

        const [_, err] = await inlineErr(saveCompany(currentUser, formState));

        if (err) {
          console.error(err);
          alert(_t("unexpected_error_please_try_again"));
        } else {
          navigate(companyRegistered ? routes.jobsUpcoming : routes.root);
        }
      } else if (!formIsValid) {
        recheckModal.open();
      }
    }
  );

  const uploaderProps = {
    data: { a: 1, b: 2 },
    multiple: false,
    beforeUpload(file) {
      setFormValue({ ...formState, image: new Parse.File(`company_logo_${new Date().getTime()}`, file) });
    },
    transformFile(file) {
      return new Promise(() => {
        // eslint-disable-next-line no-undef
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          setCompanyLogo(reader.result);
        };
      });
    },
  };

  useEffect(() => {
    if (company) {
      company
        .fetch()
        .then((r) => {
          let attributes = company.attributes;

          if (attributes.image) setCompanyLogo(attributes.image._url);

          setFormValue({
            ...attributes,
            industryType: !industryTypeList.includes(attributes.industryType) ? _t("other") : attributes.industryType,
            otherIndustry: !industryTypeList.includes(attributes.industryType) ? attributes.industryType : undefined,
            companyName: attributes.name,
            numberEmployees: attributes.numberEmployees + "",
          });
        })
        .catch(console.error);
    } else {
      setFormValue({ contactEmail: currentUser?.email });
    }
  }, []);

  const recheckModal = useModal();

  const handleTranslation = ({ translation, key }: { translation: string, key: string }) => {

    setFormValue({
      ...formState,
      [key]: translation
    });
  }

  if (!currentUser) {
    navigate(routes.signin);
    return null;
  }

  return (
    <div>
      <HeaderSubtract>
        <Form onSubmit={onSubmit}>
          <div style={{ width: "100%", position: "fixed", overflow: "hidden", zIndex: 1 }}>
            <FormControl
              title={_t("about_your_company")}
              style={{
                width: "400px",
                padding: 0,
              }}
              actionElm={<Button valid={true}>{_t("save")}</Button>}
            />
          </div>
          <div className="l-block-center" style={{ width: "400px", paddingBottom: "75px" }}>
            <br />

            <FormSectionTitle>{_t("information_visible_only_to_the_admin")}</FormSectionTitle>

            <div className="l-row">
              <div className="l-desk-6-12">
                <Field className="l-full-width" name="contactFirstName" label={_t("contact_first_name")} variant="outlined" {...genericFieldProps} />
              </div>
              <div className="l-desk-6-12">
                <Field className="l-full-width" name="contactLastName" label={_t("contact_last_name")} variant="outlined" {...genericFieldProps} />
              </div>
            </div>

            <Field className="l-full-width" name="contactEmail" label={_t("email_address")} variant="outlined" {...genericFieldProps} />

            <hr style={{ margin: "30px 0" }} />

            <FormSectionTitle>{_t("information_visible_to_workers")}</FormSectionTitle>

            {/*@ts-ignore*/}
            <Upload id="potato" component="div" {...uploaderProps}>
              <FieldLabel label={_t("company_logo")} style={{ margin: "10px 0" }} {...genericFieldProps} />
              <div
                className="l-flex-center"
                style={{
                  background: "#F9F9F9",
                  border: "2px dashed #BDBDBD",
                  borderRadius: "4px",
                  height: "70px",
                }}
              >
                <div className="l-flex-align-center">
                  {companyLogo ? (
                    <Avatar
                      src={companyLogo}
                      variant="rounded"
                      style={{
                        height: "52px",
                        width: "52px",
                        position: "relative",
                        right: "40%",
                        border: "1px solid #BDBDBD",
                      }}
                    />
                  ) : null}
                  {!companyLogo ? <UploadIcon /> : <EditIcon />}
                  <span
                    style={{
                      marginLeft: "10px",
                      color: "#325E8C",
                      fontSize: "14px",
                    }}
                  >
                    {companyLogo ? _t("edit_company_logo") : _t("add_company_logo")}
                  </span>
                </div>
              </div>
            </Upload>
            <div className="l-text-discreet" style={{ margin: "10px 0 24px 0" }}>
              {_t("jpg_gif_or_png")}
            </div>

            <Field className="l-full-width" name="companyName" label={_t("company_name")} variant="outlined" {...genericFieldProps} />
            <Field className="l-full-width" name="websiteLink" label={_t("website")} variant="outlined" {...genericFieldProps} />

            <div className="l-row">
              <div className="l-desk-7-12">
                <Field className="l-full-width" name="phoneNumber1" label={_t("phone_number")} variant="outlined" {...genericFieldProps} />
              </div>
              <div className="l-desk-7-12">
                <Field className="l-full-width" name="phoneNumber2" label={_t("phone_number2")} variant="outlined" {...genericFieldProps} />
              </div>
            </div>

            <Field
              className="l-full-width"
              name="industryType"
              label={_t("industry")}
              type="select"
              options={industryTypeList}
              placeholder=" "
              variant="outlined"
              {...genericFieldProps}
            />

            {formState.industryType == _t("other") ? (
              <Field className="l-full-width" name="otherIndustry" label={_t("please_specify_the_industry_type")} variant="outlined" {...genericFieldProps} />
            ) : null}

            <Field
              className="l-full-width"
              name="size"
              label={_t("approximate_size_of_warehouse")}
              variant="outlined"
              options={["500", "800", "1000", "2000"]}
              {...genericFieldProps}
            />

            <Field
              name="numberEmployees"
              label={_t("number_of_employees")}
              type="select"
              variant="outlined"
              style={{ minWidth: "175px" }}
              options={[_t("small_size_1_to_99"), _t("small_size_100_to_499"), _t("small_size_500_plus")]}
              {...genericFieldProps}
            />

            <div>
              <strong>{_t("training_health_safety_process")}</strong>
            </div>

            <Field
              actionButton={
                <TranslateButton
                  style={{
                    padding: '2px 12px',
                    fontSize: '14px',
                    lineHeight: '20px'
                  }}
                  textToTranslate={formState.trainingProcess}
                  onTranslate={(translation) => handleTranslation({
                    translation,
                    key: 'trainingProcessFR'
                  })}
                  valid
                >Translate from English</TranslateButton>}
              className="l-full-width"
              name="trainingProcess"
              label="English version"
              type="textarea"
              placeholder={_t("please_provide_further_information_on_your_process")}
              rows={5}
              variant="outlined"
              {...genericFieldProps}
            />

            <Field
              className="l-full-width"
              name="trainingProcessFR"
              label="French version"
              type="textarea"
              placeholder={_t("please_provide_further_information_on_your_process")}
              rows={5}
              variant="outlined"
              {...genericFieldProps}
            />

            <div>
              <strong>{_t("about_your_company_optional")}</strong>
            </div>

            <Field
              actionButton={
                <TranslateButton
                  style={{
                    padding: '2px 12px',
                    fontSize: '14px',
                    lineHeight: '20px'
                  }}
                  textToTranslate={formState.about}
                  onTranslate={(translation) => handleTranslation({
                    translation,
                    key: 'aboutFR'
                  })}
                  valid
                >Translate from English</TranslateButton>}
              label="English version"
              className="l-full-width"
              name="about"
              type="textarea"
              placeholder={_t("tell_the_workers_what_your_company_is_all_about")}
              rows={5}
              variant="outlined"
              {...genericFieldProps}
            />

            <Field
              className="l-full-width"
              name="aboutFR"
              label="French version"
              type="textarea"
              placeholder={_t("tell_the_workers_what_your_company_is_all_about")}
              rows={5}
              variant="outlined"
              {...genericFieldProps}
            />
          </div>
        </Form>
      </HeaderSubtract>

      <Modal
        title={_t("please_check_again")}
        controlRight={
          <div className="l-clickable" style={{ color: "#325E8C" }} onClick={recheckModal.close}>
            <strong>OK</strong>
          </div>
        }
        {...recheckModal.modalProps}
      >
        {_t("some_information_highlighted_in_red_are_missing")}
      </Modal>
    </div>
  );
}
