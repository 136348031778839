import React, { useEffect, useState } from "react";
import { HeaderSubtract } from "../components/layout/header-subtract";
import Rating from "@material-ui/lab/Rating";
import { Modal, useModal } from "../components/controls/modal";
import { Field } from "../components/controls/field";
import {
  createIssue,
  getJobDates,
  refreshJob,
  updateJobInstructions,
  useJob,
  createReview,
  alreadyRateWorker,
  cancelJob,
  isCancelJobAvailable,
  isWorkerInfoAvailable,
  isJobLateCancellation,
} from "../../app/services/job-service";
import { getUserById, useCurrentUser } from "../../app/services/user-service";
import { generatePath, useNavigate, useParams } from "react-router";

import { statusColor, statusDescription } from "../../app/utils/jobStatus";
import paymentStatus from "../../app/utils/paymentStatus";
import { getParseConfig, percentageCalc } from "../../app/utils/commons";
import { useForms } from "../components/controls/form";
import Joi from "joi";
import { toast } from "react-toastify";
import moment from "moment";
import { routes } from "../../app/routes";
import { dateToLocalizedString, translateForkliftName, translateJobTask, _t } from "../../app/translate/translate-wrapper";
import { getJobDateStartDateISO, getJobDateStartDateTime, getNowDateWithTz } from "../../app/utils/job-date.utils";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo_blue_background.svg";
import { WorkerSchedulePreference } from "../../app/models/user-model";

const defaultCardStyles = {
  padding: "20px 34px",
  marginBottom: "20px",
};

export default function JobDetailsPage() {
  const [rating, setRating] = useState(0 as any);
  const [showUserInfos, setShowUserInfos] = useState(false);
  const [showUserRating, setShowUserRating] = useState(false);

  const [showCancelJob, setShowCancelJob] = useState(false);
  const [jobFirstDate, setJobFirstDate] = useState(undefined as any);

  const [forkliftList, setForkliftList] = useState("");
  const [worker, setWorker] = useState(undefined as any);
  const [jobDates, setJobDates] = useState([] as any[]);

  const [lateCancellationLimit, setLateCancellationLimit] = useState(0);
  const [lateCancellationPercentage, setLateCancellationPercentage] = useState(0);
  const [lateCancellationMax, setLateCancellationMax] = useState(0);

  const reportModal = useModal();
  const cancelJobModal = useModal();
  const cancelJobLateModal = useModal();
  const descriptionModal = useModal();
  const loadingModal = useModal();
  const ratingModal = useModal();
  const successCancelJobModal = useModal();

  const currentUser = useCurrentUser();
  const params = useParams();
  const navigate = useNavigate();

  const job = useJob(params.id);

  useEffect(() => {
    refreshCurrentJob();
  }, []);

  useEffect(() => {
    if (job) {
      if (job.worker) fetchJobWorker(job.worker.id);
      if (job.payment) job.payment.fetch();

      fetchJobDates(job);
      getForkliftsList();
      getLateCancellation();
      handleShowUserInfos();
      handleShowCancelJob();
    }
  }, [!job]);

  useEffect(() => {
    if (jobDates) {
      handleShowUserRating();
    }
  }, [jobDates]);

  const calculateLateCancellationFee = () => {
    const fee = percentageCalc(lateCancellationPercentage, job?.payment.attributes.total);

    if (fee > lateCancellationMax) {
      return lateCancellationMax;
    }

    return fee;
  };

  const handleShowCancelJob = async () => {
    const isCancelJobAvaialble = await isCancelJobAvailable(job?.id);

    setShowCancelJob(isCancelJobAvaialble);
  };

  const handleShowUserRating = async () => {
    if (job?.worker) {
      const jobStatus = job?.status;
      const today = getNowDateWithTz();

      const existsReview = await alreadyRateWorker(job, job?.worker);

      let lastConfirmedDate;

      const dates = jobDates?.map((v) => v);

      dates?.reverse().forEach((jobDate) => {
        if (!lastConfirmedDate && jobDate.attributes.status.includes("confirmed")) {
          lastConfirmedDate = jobDate;
        }
      });

      if (!existsReview && lastConfirmedDate && today.isAfter(moment(getJobDateStartDateTime(lastConfirmedDate, true))) && jobStatus?.includes("confirmed")) {
        setShowUserRating(true);
      } else {
        setShowUserRating(false);
      }
    }
  };

  const handleShowUserInfos = async () => {
    const isWorkerInfoVisible = await isWorkerInfoAvailable(job?.id);

    setShowUserInfos(isWorkerInfoVisible);
  };

  const handleCancelJob = async () => {
    const isLateCancellation = await isJobLateCancellation(job?.id);

    if (isLateCancellation && job?.status.includes("confirmed")) {
      calculateLateCancellationFee();

      cancelJobLateModal.open();
    } else {
      cancelJobModal.open();
    }
  };

  const doCancelJob = async () => {
    cancelJobLateModal.close();
    cancelJobModal.close();

    try {
      loadingModal.open();

      await cancelJob(job?.id, currentUser);

      loadingModal.close();

      successCancelJobModal.open();
    } catch (err) {
      toast.error(_t("failed_to_cancel_this_job"));

      loadingModal.close();
    }
  };

  const refreshCurrentJob = async () => {
    const company = currentUser?.company;

    if (company) {
      await refreshJob(params.id, company);
    }
  };

  const getLateCancellation = async () => {
    setLateCancellationLimit(await getParseConfig("lateCancellationHoursLimit"));
    setLateCancellationPercentage(await getParseConfig("lateCancellationPercentage"));
    setLateCancellationMax(await getParseConfig("lateCancellationMaxValue"));
  };

  const getForkliftsList = async () => {
    const forklifts = job?.attributes.forklifts;

    let list: any[] = [];

    for (const forklift of forklifts) {
      const fetched = await forklift.fetch();

      list.push(translateForkliftName(fetched.name));
    }

    setForkliftList(list.join(", "));
  };

  const fetchJobDates = async (job) => {
    const dates = await getJobDates(job);
    setJobDates(dates);
  };

  const fetchJobWorker = async (id) => {
    setWorker(await getUserById(id));
  };

  const getPaymentStatus = () => {
    const payment = job?.payment?.attributes;
    const totalPayment = payment?.total?.toFixed(2);

    switch (payment?.status) {
      case paymentStatus.PRE_AUTHORIZATION_CREATED:
        return _t("pre_authorization_created");
      case paymentStatus.PRE_AUTHORIZATION_CANCELLED:
        return _t("pre_authorization_cancelled");
      case paymentStatus.PRE_AUTHORIZATION_CAPTURED:
        return `${_t("pre_authorization_captured")} $` + totalPayment;
      case paymentStatus.INVOICE_NOT_SENT:
        return `${_t("invoice_not_sent_yet")} $` + totalPayment;
      case paymentStatus.INVOICE_CANCELLED:
        return `${_t("invoice_cancelled")} $` + totalPayment;
      case paymentStatus.INVOICE_SENT:
        return `${_t("invoice_sent")} $` + totalPayment;
    }
  };

  const getTotalJobHours = () => {
    let total = 0;

    jobDates.map((d) => {
      const duration = d.attributes.duration.toString();

      let durationMultiplier = ".00";
      let durationHour = "";

      if (duration) {
        if (duration.includes(".15")) durationMultiplier = ".25";
        if (duration.includes(".30") || duration.includes(".3")) durationMultiplier = ".50";
        if (duration.includes(".45")) durationMultiplier = ".75";

        durationHour = duration.split(".")[0];
        total += Number(`${durationHour}${durationMultiplier}`);
      } else {
        total += 0;
      }
    });

    return total.toFixed(2);
  };

  const getTotalJobHoursWithCancellations = () => {
    let total = 0;

    jobDates.map((d) => {
      if (!d.attributes.status.toLowerCase().includes("cancelled")) total += d.attributes.duration;
    });

    return total.toFixed(2);
  };

  const getJobAddress = () => {
    return `${job?.address}, ${job?.city} (${job?.province}) ${job?.postalCode}`;
  };

  const editInstructionSchema = Joi.object({
    specialInstruction: Joi.string().required(),
  });

  const editInstructionsForm = useForms(editInstructionSchema, {}, async (formIsValid, formState) => {
    if (formIsValid) {
      await updateJobInstructions(job?.id, formState.specialInstruction);

      await refreshCurrentJob();

      descriptionModal.close();

      toast.success(_t("instructions_updated_successfully"));
    }
  });

  const issueSchema = Joi.object({
    descriptionText: Joi.string().required(),
  });

  const issueForm = useForms(issueSchema, {}, async (formIsValid, formState) => {
    if (formIsValid) {
      await createIssue(job, currentUser, formState.descriptionText);

      reportModal.close();

      toast.success(_t("issue_reported"));
    }
  });

  const ratingSchema = Joi.object({
    descriptionText: Joi.any(),
  });

  const ratingForm = useForms(ratingSchema, { initialFormValue: { descriptionText: undefined } }, async (formIsValid, formState) => {
    if (formIsValid) {
      await createReview(job, job?.worker, rating, formState.descriptionText);

      await handleShowUserRating();

      ratingModal.close();

      toast.success(_t("rating_sent"));
    }
  });

  const getJobEndDate = () => {
    if (jobDates && jobDates.length > 0) {
      const lastDate = getJobDateStartDateISO(jobDates[jobDates.length - 1]);

      if (lastDate) {
        return `${lastDate.substring(8, 10)}/${lastDate.substring(5, 7)}/${lastDate.substring(0, 4)}`;
      }
    }
  };

  const getDateJobDuration = (dateJob) => {
    const durationSplited = dateJob.duration.toFixed(2).split(".");

    return `${durationSplited[0]}:${durationSplited[1]}`;
  };

  const getJobStartDate = () => {
    if (job) {
      const startDate = getJobDateStartDateISO(jobDates[0]);

      if (startDate) {
        return `${startDate.substring(8, 10)}/${startDate.substring(5, 7)}/${startDate.substring(0, 4)}`;
      }
    }
  };

  const getScheduleDate = (dateJob) => {
    const dateString = getJobDateStartDateISO(dateJob);

    if (dateString) {
      return `${dateString.substring(8, 10)}/${dateString.substring(5, 7)}`;
    }
  };

  const getWorkerPicture = () => {
    const workerPicture = worker?.workerIdentity?.get("profilePicture");
    const style = { overflow: "hidden", borderRadius: "5px", width: "78px", height: "78px" };

    if (workerPicture) {
      return <img src={workerPicture._url} style={style} />;
    }

    return <LogoIcon style={style} />;
  };

  if (!job) {
    return (
      <HeaderSubtract>
        <div className="l-content">
          <div className="t-surface-card l-text-discreet l-inline-center" style={{ margin: "30px", padding: "25px" }}>
            {_t("loading")}
          </div>
        </div>
      </HeaderSubtract>
    );
  }

  return (
    <div>
      <HeaderSubtract>
        <div className="l-row l-content" style={{ marginTop: "24px" }}>
          <div className="l-desk-4-12">
            <div className="t-surface-card" style={defaultCardStyles}>
              <div>
                <strong style={{ fontSize: "18px", margin: "5px 0", display: "block" }}> {_t("status")}</strong>
              </div>
              <div>
                <strong style={{ color: job.statusColor, margin: "5px 0", display: "block" }}>{job.statusDescription}</strong>
              </div>
              <div style={{ color: "#A8A8A8", margin: "5px 0", display: "block" }}>{job.statusDetailDescription(lateCancellationLimit)}</div>
            </div>

            {job.worker ? (
              <div className="t-surface-card" style={defaultCardStyles}>
                <strong style={{ fontSize: "18px", margin: "5px 0 15px 0", display: "block" }}> {_t("your_worker")}</strong>
                {getWorkerPicture()}

                {worker ? (
                  <strong style={{ margin: "20px 0 15px 0", display: "block" }}>{`${worker?.workerIdentity?.get("firstName")} ${worker?.workerIdentity?.get(
                    "lastName"
                  )}`}</strong>
                ) : (
                  <strong style={{ margin: "20px 0 15px 0", display: "block" }}>{`${worker?.workerIdentity?.get("firstName")}`}</strong>
                )}
                <div style={{ margin: "15px 0", display: "block", color: "#666666" }}>
                  {worker?.schedulePreference == WorkerSchedulePreference.FULL_TIME ? _t("looking_full_time") : _t("looking_part_time")}
                </div>
                {showUserInfos ? (
                  <div style={{ margin: "15px 0", display: "block", color: "#666666" }}>{worker?.workerIdentity?.get("phoneNumber")}</div>
                ) : null}
                {showUserInfos ? <div style={{ margin: "15px 0", display: "block", color: "#666666" }}>{worker?.workerIdentity?.get("email")}</div> : null}
              </div>
            ) : null}

            {job.worker && showUserRating ? (
              <div className="l-inline-center t-surface l-clickable" style={{ ...defaultCardStyles, border: "2px solid #e3e3e3" }} onClick={ratingModal.open}>
                <div>
                  <strong>{_t("rate_your_worker")}</strong>
                </div>
                <div style={{ margin: "20px auto", display: "inline-block" }}>
                  <Rating value={0} size="large" disabled />
                </div>
              </div>
            ) : null}

            <div
              className="t-surface l-clickable"
              style={{ ...defaultCardStyles, border: "2px solid #e3e3e3" }}
              onClick={() => {
                navigate(generatePath(routes.jobDuplicate, { id: job.id }));
              }}
            >
              <div className="l-inline-center" style={{ color: "#325E8C" }}>
                <strong>{_t("duplicate_this_job")}</strong>
              </div>
            </div>

            <div className="t-surface l-clickable" style={{ ...defaultCardStyles, border: "2px solid #e3e3e3" }} onClick={reportModal.open}>
              <div className="l-inline-center" style={{ color: "#325E8C" }}>
                <strong>{_t("report_an_issue")}</strong>
              </div>
            </div>

            {showCancelJob ? (
              <div className="t-surface l-clickable" style={{ ...defaultCardStyles, border: "2px solid #e3e3e3" }} onClick={() => handleCancelJob()}>
                <div className="l-inline-center" style={{ color: "#EB5757" }}>
                  <strong>{_t("cancel_this_job")}</strong>
                </div>
              </div>
            ) : null}
          </div>

          <div className="l-desk-8-12">
            <div className="t-surface-card" style={defaultCardStyles}>
              <strong style={{ fontSize: "18px", marginBottom: "30px", display: "block" }}>{_t("status")}</strong>

              <table className="table-cards-transpose">
                <colgroup>
                  <col style={{ minWidth: "250px" }} />
                  <col style={{ minWidth: "250px" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <td>
                      <strong>{_t("starting_date")}</strong>
                    </td>
                    <td>{getJobStartDate()}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{_t("end_date")}</strong>
                    </td>
                    <td>{jobDates.length > 0 ? getJobEndDate() : "-"}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{_t("working_days")}</strong>
                    </td>
                    <td>{jobDates.length}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{_t("total_hours")}</strong>
                    </td>
                    <td>{getTotalJobHours()}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{_t("warehouse_address")}</strong>
                    </td>
                    <td>{getJobAddress()}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{_t("task")}</strong>
                    </td>
                    <td>{translateJobTask(job?.mainTask)}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{_t("task_description")}</strong>
                    </td>
                    <td>{job?.getTaskDescription()}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{_t("weight_to_lift")}</strong>
                    </td>
                    <td>
                      {_t("up_to")} {job?.liftWeight} {_t("lbs")}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{_t("warehouse_experience")}</strong>
                    </td>
                    <td>{job?.requireWarehouseExperience ? _t("required") : _t("optional")}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{_t("forklift")}</strong>
                    </td>
                    <td>{forkliftList || "-"}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{_t("required_spoken_language")}</strong>
                    </td>
                    <td style={{ textTransform: "capitalize" }}>{job?.languages?.map((l) => _t(l.toLowerCase())).join(", ") || "-"}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="t-surface-card" style={defaultCardStyles}>
              <div className="l-flex-between" style={{ marginBottom: "30px" }}>
                <strong style={{ fontSize: "18px" }}>{_t("instructions_for_worker")}</strong>
                <div
                  className="l-clickable"
                  style={{ color: "#325E8C" }}
                  onClick={() => {
                    editInstructionsForm.setFormValue({ specialInstruction: job?.specialInstruction });

                    descriptionModal.open();
                  }}
                >
                  <strong>{_t("edit")}</strong>
                </div>
              </div>

              <table className="table-cards">
                <colgroup>
                  <col style={{ width: "275px" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <td>
                      <strong>{_t("your_instructions_for_the_worker")}</strong>
                    </td>
                    <td>{job?.specialInstruction}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="t-surface-card" style={defaultCardStyles}>
              <strong style={{ fontSize: "18px", marginBottom: "30px", display: "block" }}>{_t("schedule_details")}</strong>

              <table className="table-cards">
                <colgroup>
                  <col style={{ width: "150px" }} />
                  <col style={{ width: "100px" }} />
                  <col style={{ width: "150px" }} />
                  <col style={{ width: "200px" }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>{_t("date")}</th>
                    <th>{_t("start")}</th>
                    <th>{_t("duration")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {jobDates.map((v, i) => {
                    const dateJob = v.attributes;
                    return (
                      <tr key={i}>
                        <td style={{ textTransform: "capitalize" }}>{`${dateToLocalizedString(dateJob.date).substring(0, 3)} ${getScheduleDate(v)}`}</td>
                        <td>{dateJob.startTime}</td>
                        <td>{getDateJobDuration(dateJob)} hrs</td>
                        <td style={{ color: statusColor(dateJob.status) }}>{statusDescription(dateJob.status)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="t-surface-card" style={defaultCardStyles}>
              <strong style={{ fontSize: "18px", marginBottom: "30px", display: "block" }}>{_t("price_and_payment")}</strong>

              <strong style={{ marginBottom: "20px", display: "block" }}>{_t("original_price")}</strong>
              <table className="table-cards" style={{ width: "100%" }}>
                <tr>
                  <td>1 {_t("worker")}</td>
                  <td>{getTotalJobHours()} hrs</td>
                </tr>
                <tr>
                  <td>{_t("hourly_cost")}</td>
                  <td>${job?.hourlyCost.toFixed(2)}/h</td>
                </tr>
                <tr>
                  <td>{_t("basic_hourly_rate")}</td>
                  <td>${job?.hourlyRate.toFixed(2)}/h</td>
                </tr>
                <tr>
                  <td>{_t("subtotal")}</td>
                  <td>${job?.payment?.attributes?.subtotal?.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>{_t("taxes")}</td>
                  <td>${job?.payment?.attributes?.tax?.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>
                    <strong>{_t("original_total")}</strong>
                  </td>
                  <td>
                    <strong>${job?.payment?.attributes?.total?.toFixed(2)}</strong>
                  </td>
                </tr>
              </table>

              {job?.status == "confirmedWithCancelledDays" ? (
                <>
                  <hr style={{ margin: "20px 0" }} />

                  <strong style={{ marginBottom: "20px", display: "block" }}>{_t("updated_price_based_on_cancellations")}</strong>

                  <table className="table-cards" style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td>1 {_t("worker")}</td>
                        <td>{getTotalJobHoursWithCancellations()} hrs</td>
                      </tr>
                      <tr>
                        <td>{_t("basic_hourly_rate")}</td>
                        <td>${job?.hourlyCost.toFixed(2)}/h</td>
                      </tr>
                      <tr>
                        <td>
                          {_t("cancellation_fees")}
                          <div className="l-text-discreet" style={{ width: "275px", fontSize: "12px", marginTop: "10px" }}>
                            {_t("if_you_cancelled_late_some_dates")}
                          </div>
                        </td>
                        <td>${job?.cancellationFee ? job?.cancellationFee.toFixed(2) : "0.00"}</td>
                      </tr>
                      <tr>
                        <td>{_t("subtotal")}</td>
                        <td>${job?.payment?.attributes?.updatedSubtotal?.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td>{_t("taxes")}</td>
                        <td>${(job?.payment?.attributes?.tax - job?.payment?.attributes?.taxRefunded).toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>{_t("updated_total")}</strong>
                        </td>
                        <td>${job?.payment?.attributes?.updatedTotal?.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>{_t("refund")}</strong>
                        </td>
                        <td>${job?.payment?.attributes?.amountRefunded?.toFixed(2)}</td>
                      </tr>
                    </tbody>
                  </table>
                </>
              ) : null}

              <hr style={{ margin: "20px 0" }} />

              <strong style={{ marginBottom: "20px", display: "block" }}>{_t("payment")}</strong>

              <table className="table-cards" style={{ width: "100%" }}>
                <tr>
                  <td>{_t("payment_method")}</td>
                  <td>
                    {job?.payment?.attributes?.provider == "stripe" ? `${_t("credit_card_ending_with")} ${currentUser?.attributes.ccard}` : _t("invoice")}
                  </td>
                </tr>
                <tr>
                  <td>{_t("payment_status")}</td>
                  <td>{getPaymentStatus()}</td>
                </tr>
                {job?.payment?.attributes?.amountRefunded ? (
                  <tr>
                    <td>{_t("refund")}</td>
                    <td>
                      {_t("refund_sent")} ${job?.payment?.attributes?.amountRefunded?.toFixed(2)}
                    </td>
                  </tr>
                ) : null}
              </table>
            </div>
          </div>
        </div>
      </HeaderSubtract>

      <Modal
        title={_t("cancel_this_job_question")}
        controlLeft={
          <div className="l-clickable" style={{ color: "#666" }} onClick={cancelJobLateModal.close}>
            <strong>{_t("cancel")}</strong>
          </div>
        }
        controlRight={
          <div className="l-clickable" style={{ color: "#325E8C" }} onClick={() => doCancelJob()}>
            <strong>{_t("yes")}</strong>
          </div>
        }
        {...cancelJobLateModal.modalProps}
      >
        {_t("if_you_cancel_now_you_will_get_cancellation_fees")} ${calculateLateCancellationFee().toFixed(2)}
      </Modal>

      <Modal
        title={_t("cancel_this_job_question")}
        controlLeft={
          <div className="l-clickable" style={{ color: "#666" }} onClick={cancelJobModal.close}>
            <strong>{_t("cancel")}</strong>
          </div>
        }
        controlRight={
          <div className="l-clickable" style={{ color: "#325E8C" }} onClick={() => doCancelJob()}>
            <strong>{_t("yes")}</strong>
          </div>
        }
        {...cancelJobModal.modalProps}
      >
        {_t("if_you_cancel_now_you_will_get_100_refund")}
      </Modal>

      <Modal
        title={_t("having_an_issue")}
        controlLeft={
          <div className="l-clickable" style={{ color: "#666" }} onClick={reportModal.close}>
            <strong>{_t("cancel")}</strong>
          </div>
        }
        controlRight={
          <div className="l-clickable" style={{ color: "#325E8C" }} onClick={issueForm.onSubmit}>
            <strong>{_t("send")}</strong>
          </div>
        }
        {...reportModal.modalProps}
      >
        <div style={{ marginTop: "8px" }}>{_t("describe_your_issue_to_the_admin")}</div>

        <Field
          className="l-full-width"
          name="descriptionText"
          type="textarea"
          rows={4}
          variant="outlined"
          containerStyle={{ marginTop: "15px" }}
          {...issueForm.genericFieldProps}
        />
        <div style={{ fontSize: "12px", color: "#666666" }}>
          <div>{_t("if_you_need_assistance_call_us")}</div>
          <div>{_t("monday_to_friday")}</div>
        </div>
      </Modal>

      <Modal
        title={_t("rate_your_worker")}
        controlLeft={
          <div className="l-clickable" style={{ color: "#666" }} onClick={ratingModal.close}>
            <strong>{_t("cancel")}</strong>
          </div>
        }
        controlRight={
          <div className="l-clickable" style={{ color: "#325E8C" }} onClick={ratingForm.onSubmit}>
            <strong>{_t("save")}</strong>
          </div>
        }
        {...ratingModal.modalProps}
      >
        <Rating
          value={rating}
          size="large"
          onChange={(_, newValue) => {
            setRating(newValue);
          }}
        />

        <div style={{ marginTop: "8px" }}>{_t("your_comment_optional")}</div>

        <Field
          className="l-full-width"
          name="descriptionText"
          type="textarea"
          rows={4}
          placeholder={_t("your_comment_optional")}
          variant="outlined"
          containerStyle={{ marginTop: "15px" }}
          {...ratingForm.genericFieldProps}
        />
      </Modal>

      <Modal
        title={_t("edit_instructions_for_worker")}
        controlLeft={
          <div className="l-clickable" style={{ color: "#666" }} onClick={descriptionModal.close}>
            <strong>{_t("cancel")}</strong>
          </div>
        }
        controlRight={
          <div className="l-clickable" style={{ color: "#325E8C" }} onClick={editInstructionsForm.onSubmit}>
            <strong>{_t("save")}</strong>
          </div>
        }
        {...descriptionModal.modalProps}
      >
        <Field
          className="l-full-width"
          name="specialInstruction"
          type="textarea"
          rows={4}
          variant="outlined"
          containerStyle={{ marginTop: "15px" }}
          {...editInstructionsForm.genericFieldProps}
        />
      </Modal>

      <Modal
        title={_t("job_cancelled")}
        {...successCancelJobModal.modalProps}
        controlRight={
          <div
            className="l-clickable"
            style={{ color: "#325E8C" }}
            onClick={() => {
              navigate(routes.root);
              window.location.reload();
            }}
          >
            <strong>Ok</strong>
          </div>
        }
      >
        {_t("job_successfully_cancelled")}
      </Modal>

      <Modal title="Loading" {...loadingModal.modalProps}>
        {_t("we_are_processing_your_request")}
      </Modal>
    </div>
  );
}
