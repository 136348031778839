export default {
  rating_sent: "Rating sent successfully!",
  issue_reported: "Issue reported successfully!",
  create_a_new_job: "Create a new job",
  about_your_company: "About your company",
  contact_us: "Contact us",
  rate_us: "Rate us",
  terms_and_conditions: "Terms and Conditions",
  privacy_policy: "Privacy Policy",
  log_out: "Log out",
  no: "No",
  yes: "Yes",
  you_will_be_disconnect_from_your_account: "You will be disconnected from your account.",
  cancel: "Cancel",
  send: "Send",
  describe_your_issue_to_the_admin: "Describe your issue to the admin",
  if_you_need_assistance_call_us: "If you need a direct assistance, call us at 1(844) 4AU-KAZI.",
  monday_to_friday: "Monday to Friday, 9:00AM to 5:00PM.",
  save: "Save",
  having_an_issue: "Having an Issue?",
  how_is_your_experience: "How is your experience?",
  let_us_know_if_we_should_improve_things: "Let us know if we should improve things",
  unexpected_error_while_comunicating: "Unexpected error while communicating with the server, try again or contact us for support.",
  check_your_credit_card_information: "Check your credit card information",
  not_found: "Not found",
  pending: "Pending",
  confirmed: "Confirmed",
  worker_cancelled: "Worker cancelled",
  worker_cancelled_late: "Worker cancelled late",
  you_cancelled: "You cancelled",
  you_cancelled_late: "You cancelled late",
  admin_cancelled: "Admin cancelled",
  confirmed_but_contains_cancelled_days: "Confirmed but contains cancelled days",
  card_number: "Card number",
  name_of_card_holder: "Name of card holder",
  expiration_date: "Expiration date (MM/YY)",
  cvv_code: "CVV code",
  shipping: "Shipping",
  receiving: "Receiving",
  order_picking: "Order Picking",
  other: "Other",
  critical_error_happened: "CRITICAL ERROR OCCURRED. Contact us immediately to undo the credit card transation.",
  required: "Required",
  optional: "Optional",
  how_many_workers_do_you_need: "How many workers do you need?",
  numbers_of_workers: "Number of workers",
  when_is_the_job: "When is the job?",
  selected_dates: "selected dates",
  please_fill_out_the_starting_time:
    "Please fill out the starting time and day duration for each selected date.It is your responsibility to be compliant with the",
  cnesst_rules: "CNESST rules",
  starts_at: "Starts at",
  duration_hrs: "Duration (hrs)",
  copy_this_setup_for_all_dates: "Copy this schedule for all the following dates ",
  where_is_the_job: "Where is the job?",
  address: "Address",
  city: "City",
  postal_code: "Postal Code",
  province: "Province",
  we_could_not_find_that_address: "We could not find that address",
  what_is_the_job: "What is the job?",
  main_task: "Main Task",
  please_specify_the_main_task: "Please specify the main task (Max 30 characters)",
  up_to: "Up to",
  lbs: "lbs",
  which_competencies_are_requested: "Which competencies are requested?",
  warehouse_experience: "Warehouse experience",
  free_options: "Free options",
  forklift_experience: "Forklift experience (optional)",
  plus_3_for_selected_options: " / hour for 1, 2 or 3 selected option(s):",
  required_spoken_languages: "Required spoken language(s) (optional)",
  english: "English",
  french: "French",
  special_instructions_for_worker: "Special instructions for worker? (optional)",
  this_will_only_be_seen_by_the_worker:
    "This will only be seen by the worker who takes the job. If you have any special instruction regarding the job, please list here. This can be edited later.",
  please_provide_instructions_and_information:
    "Please provide instructions and information on getting into the building (such as where to park, which entrance to use/code to call) as well as any amenities onsite (free coffee, ability to purchase food/neighboring restaurants...)",
  payment_method: "Payment Method",
  select_a_payment_method_to_publish_the_job:
    "Select a payment method to publish the job. The amount will be secured, and the payment will be processed when a worker confirms the job.",
  use_a_credit_card_linked_to_my_account: "Use a credit card linked to my account",
  no_credit_card_linked_to_my_account: "No credit card linked to your account",
  add_a_credit_card: "Add a credit card",
  job: "Job",
  workers: "workers",
  basic_hourly_rate: "Hourly rate",
  forklift_option: "Forklift option",
  subtotal: "Subtotal",
  taxes: "Taxes",
  total: "Total",
  continue: "Continue",
  publish_the_job: "Publish the job",
  as_you_selected_several_workers: "As you selected several workers, several jobs will be published and paid individually.",
  some_information_highlighted_in_red_are_missing: "Some information highlighted in red are missing or incorrect.",
  please_wait: "Please wait...",
  publish_the_job_question: "Publish the job?",
  please_check_again: "Please check again",
  workers_will_be_notified_payment: "Workers will be notified. Payment will get captured when a worker is confirmed.",
  workers_will_be_notified_invoice: "Workers will be notified. You will get an invoice when a worker is confirmed.",
  job_published: "Job published!",
  you_will_get_notified_when_a_worker_takes_the_job: "You will get notified when a worker takes the job.",
  please_wait_we_are_processing_your_job: "Please wait, we are processing your job(s)",
  please_wait_loading_your_job: "Please wait, loading your job...",
  food: "Food",
  healthcare_pharma: "Healthcare/pharma",
  manufacturing: "Manufacturing",
  logistics: "Logistics",
  fashion: "Fashion",
  ecommerce: "Ecommerce",
  technology: "Technology",
  unexpected_error_please_try_again: "Unexpected error, please try again",
  information_visible_only_to_the_admin: "Information visible only to the admin",
  contact_first_name: "Contact first name",
  contact_last_name: "Contact last name",
  email_address: "Email Address",
  information_visible_to_workers: "Information visible to workers",
  company_logo: "Company logo (Optional)",
  edit_company_logo: "Edit company logo",
  add_company_logo: "Add company logo",
  jpg_gif_or_png: "JPG, GIF or PNG. Max size of 5MB.",
  company_name: "Company Name",
  website: "Website (Optional)",
  phone_number: "Phone Number",
  phone_number2: "Phone Number 2 (Optional)",
  industry: "Industry",
  please_specify_the_industry_type: "Please specify the industry type (Max 30 characters)",
  approximate_size_of_warehouse: "Approximate size of warehouse (in sq.ft.)",
  number_of_employees: "Number of employees",
  small_size_1_to_99: "1-99 small",
  small_size_100_to_499: "100-499 medium",
  small_size_500_plus: "500+ large",
  training_health_safety_process: "Training / health safety process",
  please_provide_further_information_on_your_process:
    "Please provide further information on your process and safety training to help your worker be prepared for what to expect! i.e: 2 hours training on process, 30 mins safety/ protocols training",
  about_your_company_optional: "About your company (Optional)",
  tell_the_workers_what_your_company_is_all_about:
    "Tell the workers what your company is all about. Copy some information from your website or tell them why you are a great place to work!",
  user_not_found: "User not found",
  unknown_error_happened: "Unknown error happened",
  we_sent_you_an_email_to_reset_your_password: "We sent you an email to reset your password",
  reset_your_password: "Reset your password",
  send_recovery_email: "Send recovery email",
  invalid_email_use_other_email_for_recovering_the_password: "Invalid email, use other email for recovering the password",
  a_recovery_email_has_been_sent: "A recovery email has been sent",
  error: "Error",
  success: "Success",
  failed_to_cancel_this_job: "Failed to cancel this job.",
  pre_authorization_created: "Pre-authorization created.",
  pre_authorization_cancelled: "Pre-authorization cancelled.",
  pre_authorization_captured: "Pre-authorization captured:",
  invoice_not_sent_yet: "Invoice not sent yet:",
  invoice_cancelled: "Invoice cancelled:",
  invoice_sent: "Invoice sent:",
  instructions_updated_successfully: "Instructions updated successfully!",
  loading: "Loading...",
  status: "Status",
  your_worker: "Your worker",
  rate_your_worker: "Rate your worker",
  duplicate_this_job: "Duplicate this job",
  report_an_issue: "Report an issue",
  cancel_this_job: "Cancel this job",
  starting_date: "Starting Date",
  end_date: "End Date",
  working_days: "Working days",
  total_hours: "Total hours",
  warehouse_address: "Warehouse address",
  task: "Task",
  weight_to_lift: "Weight to lift",
  forklift: "Forklift",
  required_spoken_language: "Required spoken language",
  instructions_for_worker: "Instructions for worker",
  edit: "Edit",
  your_instructions_for_the_worker: "Your instructions for the worker",
  schedule_details: "Schedule details",
  date: "DATE",
  start: "START",
  duration: "DURATION",
  price_and_payment: "Price and Payment",
  original_price: "ORIGINAL PRICE",
  original_total: "Original total",
  updated_price_based_on_cancellations: "UPDATED PRICE BASED ON CANCELLATION(S)",
  cancellation_fees: "Cancellation fees",
  if_you_cancelled_late_some_dates:
    "If you cancelled late some dates, cancellation fees equal the smallest amount between 150$ and 25% of the subtotal for the dates you cancelled late",
  updated_total: "Updated total",
  refund: "Refund",
  payment: "PAYMENT",
  credit_card_ending_with: "Credit card ending with",
  invoice: "Invoice",
  payment_status: "Payment status",
  refund_sent: "Refund sent:",
  cancel_this_job_question: "Cancel this job?",
  if_you_cancel_now_you_will_get_cancellation_fees: "If you cancel now, you will get cancellation fee of",
  if_you_cancel_now_you_will_get_100_refund: "If you cancel now, you will get a 100% refund.",
  your_comment_optional: "Your comment (optional, worker will not see it)",
  edit_instructions_for_worker: "Edit instructions for worker",
  job_cancelled: "Job cancelled!",
  job_successfully_cancelled: "Job successfully cancelled!",
  we_are_processing_your_request: "We are processing your request...",
  tap_on_the_button_create_a_new_job: "Tap on the button “Create a new job” to start.",
  check_your_email_and_password_and_try_again: "Check your email and password and try again",
  log_into_your_existing_company_account: "Log into your existing company account",
  password: "Password",
  remember_me: "Remember me",
  password_forgotten: "Password forgotten?",
  login: "Login",
  dont_have_an_account: "Don't have an account?",
  sign_up: "Sign up",
  this_email_is_already_registered: "This email is already registered",
  something_wrong_happened: "Something wrong happened, try again later or contact our support",
  create_your_company_account: "Create your company account",
  by_clicking_on_sign_up: "By clicking on “Sign up”, I agree to our",
  and: "and",
  already_have_an_account_question: "Already have an account?",
  log_in: "Log in",

  we_have_an_worker_for_you: "We have a worker for you.",
  we_have_not_found_a_worker_refund_100: "We have not found a worker for this job. You get a 100% refund.",
  we_are_looking_for_a_worker: "We are looking for a worker.",
  the_worker_is_no_longer_available: "The worker is no longer available to take the job. You will receive a complete refund.",
  you_cancelled_this_job_refund_100: "You cancelled this job. You get a 100% refund.",
  you_cancelled_this_job_under: "You cancelled this job under",
  hours_before_it_starts: "hours before it starts. You get a partial refund.",
  check_the_schedule_details: "Check the schedule details",
  other_task: "Other Task",
  other_industry_type: "Other Industry Type",
  lift_weight: "Lift Weight",
  required_languages: "Required languages",
  name: "Name",
  size: "Size",
  training_process: "Training process",
  about: "About",
  description: "Description",
  upcoming_jobs: "Upcoming Jobs",
  history: "History",
  no_cards_registered: "No cards registered",
  starting_time: "Starting time",
  days: "Days",
  hours: "Hours",
  price: "Price",
  worker: "Worker",
  upcoming: "Upcoming",
  jobs: "jobs",
  no_without_jobs: "no",
  pallet_jack: "Pallet Jack",
  reach_truck: "Reach Truck",
  cherry_picker: "Cherry Picker",
  counter_balance_truck: "Counter Balance Truck",
  hourly_rate_offered_to_workers: "Hourly rate offered to workers (Min [hourlyRate])",
  hourly_rate_service_fee: "A [serviceFee]% service fee will be added to your total.",
  additional_email_notify_title: "If you would like someone else to receive \nnotifications on this job, please enter their \nemail below. (optional)",
  including_x_markup_fee: "Including [serviceFee]% Markup",
  want_to_rehire_a_past_worker: "Want to rehire a past worker?",
  rehire_past_worker_text: "Select a worker from the drop down and it will be sent exclusively to them. Ideally, the worker is aware it is coming!",
  if_you_want_rehire_past_worker_keep_above: "If you want to rehire a past worker, keep “1” above.",
  none_selected: "None selected",
  please_provide_more_details_about_this_job_optional_label: "Please provide more details about this job (optional)",
  please_provide_more_details_about_this_job_optional_placeholder: "Briefly describe the main task.",
  task_description: "Task description",
  hourly_cost: "Hourly cost",
  review_past_workers: "Review Past Workers",
  review_past_workers_text:
    "For a better experience, please rate your workers in history and let us know your feedback! Not visible to worker, may be used for coaching.",
  later: "Later",
  rate_them_now: "Rate them now",
  rate_worker: "Rate worker",
  rate_worker_button: "Rate worker",
  load_more: "Load more",
  looking_full_time: "Looking for full time",
  looking_part_time: "Looking for part time",
  completed_hours: "Completed hours",
  your_average_rating: "Your average rating",
  your_notes_about_this_worker: "Your notes about this worker",
  type_your_notes_here: "Type your notes here. Only you and the admin can see these notes.",
  add: "Add",
  delete: "Delete",
  past_workers: "Past workers",
  workers_who_already_worked_for_you: " worker(s) who already worked for you",
  notes_saved: "Notes saved!",
  comment_deleted: "Comment deleted!",
  english_version: "English version",
  french_version: "French version",
  translate_from_english: "Translate from English",
  translate_from_french: "Translate from French",
  filter_workers: "Workers(s)",
  add_filters: "Add filters",
  filters: "Filter(s)",
  job_status: "Job Status",
  filter_task: "Task",
  apply: "Apply",
  reset: "Reset",
};
